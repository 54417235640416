import react.create
import react.dom.client.createRoot
import web.dom.document
import web.html.HTMLElement

fun main() {
    val container = document.querySelector("div") as HTMLElement
    val node = when (container.id) {
        "root" ->  Uploader.create {
            uploaded = emptyList()
        }
        "404" -> FileNotFound.create {}
        else -> return
    }
    createRoot(container).render(node)
}