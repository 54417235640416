import csstype.*
import csstype.Auto.Companion.auto
import csstype.Cursor.Companion.pointer
import csstype.NamedColor.Companion.white
import csstype.None.Companion.none
import emotion.react.css
import js.core.asList
import nand.moe.common.DOMAIN_NAME
import nand.moe.common.MAX_UPLOAD_SIZE_BYTES
import nand.moe.common.UPLOAD_EXPIRE_TIME_MS
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.useEffect
import react.useState
import web.dom.document
import web.file.File
import web.html.HTMLElement
import web.html.HTMLFormElement
import web.html.InputType
import web.http.FormData
import web.navigator.navigator
import web.window.WindowTarget
import web.xhr.XMLHttpRequest
import kotlin.math.round

external interface UploaderProps : Props {
    var dragging: Boolean
    var error: String?
    var uploadFileName: String?
    var uploadProgress: Int
    var uploaded: List<String>
    var copiedFileName: String?
}

private val uploadQueue = ArrayList<File>()
private var uploadBusy = false

val Uploader = FC<UploaderProps> { props ->
    var dragging by useState(props.dragging)
    var error by useState(props.error)
    var uploadFileName by useState(props.uploadFileName)
    var uploadProgress by useState(props.uploadProgress)
    var uploaded by useState(props.uploaded)
    var copiedFileName by useState(props.copiedFileName)
    
    val uploadFunc: (File) -> Unit = { file ->
        if (!uploadBusy) {
            uploadBusy = true
            error = null
            upload(
                file = file,
                onError = {
                    uploadBusy = false
                    error = it
                    uploadFileName = null
                    uploadProgress = 0
                },
                onProgress = { fileName, progress ->
                    uploadFileName = fileName
                    uploadProgress = progress
                },
                onFinish = { url ->
                    uploadBusy = false
                    uploaded += url
                    uploadFileName = null
                    uploadProgress = 0
                },
            )
        } else {
            uploadQueue += file
        }
    }

    useEffect {
        if (!uploadBusy && uploadQueue.isNotEmpty()) {
            uploadFunc(uploadQueue.removeAt(0))
        }
    }
    
    val maxSizeMb = MAX_UPLOAD_SIZE_BYTES / 1_000_000L
    val expireHours = UPLOAD_EXPIRE_TIME_MS / (60 * 60 * 1000L)
    
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            marginLeft = auto
            marginRight = auto
            gap = 1.em
        }
        div {
            css {
                fontFamily = FontFamily.monospace
                fontSize = (1.5).em
            }
            +DOMAIN_NAME
        }
        div {
            css {
                fontFamily = FontFamily.monospace
            }
            +"${maxSizeMb}mb max – expires after $expireHours hours"
        }
        form {
            css {
                centerBox()
                minHeight = 10.em
                display = Display.flex
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                background = when {
                    error != null -> ERROR_RED_BG
                    dragging -> DRAG_BG
                    else -> none
                }
                borderColor = if (error != null) ERROR_RED_TEXT else white
            }
            div {
                css {
                    labelText(if (uploadFileName != null) (1.5).em else 2.em)
                    color = if (error != null) ERROR_RED_TEXT else white
                }
                + when {
                    error != null -> error
                    uploadFileName != null -> "Uploading $uploadFileName $uploadProgress%"
                    dragging -> "Drop file to upload"
                    else -> "Select or drop files"
                }
            }
            input {
                id = "upload"
                type = InputType.file
                onChange = { event ->
                    event.target.files?.asList()?.forEach { file ->
                        uploadFunc(file)
                    }
                }
                css {
                    display = none
                }
            }
            action = "upload"
            method = "post"
            encType = "multipart/form-data"
            onClick = {
                //uploaded += "Test link.jpg"
                (document.querySelector("input#upload") as? HTMLElement)?.click()
            }
            onDragEnter = { event ->
                event.preventDefault()
                error = null
                dragging = true
            }
            onDragOver = { event -> event.preventDefault() }
            onDragExit = { event ->
                event.preventDefault()
                dragging = false
            }
            onDrop = { event ->
                event.preventDefault()
                dragging = false
                event.dataTransfer.items.asList().forEach { item ->
                    if (item.kind == "file") {
                        item.getAsFile()?.let { uploadFunc(it) }
                    }
                }
            }
        }
        uploaded.forEach { fileName ->
            div {
                css {
                    centerBox()
                    position = Position.relative
                    if (copiedFileName == fileName) {
                        background = COPIED_GREEN_BG
                    }
                }
                div {
                    css {
                        labelText(1.em)
                    }
                    +fileName
                }
                a {
                    css {
                        outline = none
                        userSelect = none
                        position = Position.absolute
                        right = 0.75.em
                        bottom = 0.65.em
                    }
                    img {
                        css {
                            width = 1.5.em
                            height = 1.5.em
                        }
                        src = "/static/newtab.svg"
                    }
                    href = fileName
                    target = WindowTarget._blank
                }
                onClick = { event ->
                    val target = event.target as? HTMLElement
                    if (target?.tagName == "DIV") {
                        event.preventDefault()
                        navigator.clipboard.writeText(fileName)
                        copiedFileName = fileName
                    }
                }
            }
        }
    }
    div {
        css {
            position = Position.fixed
            right = 0.px
            bottom = (-5).px
        }
        img {
            src = "/static/capycorner.webp"
        }
    }
    document.onpaste = { event ->
        event.clipboardData?.items?.asList()?.forEach { item ->
            if (item.kind == "file") {
                item.getAsFile()?.let { uploadFunc(it) }
            }
        }
    }
}

private fun PropertiesBuilder.centerBox() {
    width = 40.em
    maxWidth = 90.pct
    paddingTop = 1.em
    paddingBottom = 1.em
    borderRadius = (0.5).em
    border = Border(2.px, LineStyle.solid, white)
    textAlign = TextAlign.center
    fontFamily = FontFamily.monospace
    cursor = pointer
}

private fun PropertiesBuilder.labelText(size: FontSize) {
    fontSize = size
    userSelect = none
}

private fun upload(
    file: File,
    onError: (String) -> Unit,
    onProgress: (String, Int) -> Unit,
    onFinish: (String) -> Unit,
) {
    // Check file size before doing anything else
    if (file.size > MAX_UPLOAD_SIZE_BYTES) {
        onError("File exceeds max upload size")
        return
    }

    // Pull endpoint 
    val form = document.querySelector("form") as? HTMLFormElement ?: run {
        onError("Missing form element")
        return
    }
    
    with(XMLHttpRequest()) {
        upload.onprogress = { event ->
            if (event.lengthComputable) {
                val percentage = round((event.loaded * 100) / event.total).toInt()
                onProgress(file.name, percentage)
            }
        }
        onreadystatechange = {
            if (readyState.toInt() == 4) {
                val responseTrimmed = responseText.trim()
                if (status == 200) {
                    onFinish(responseTrimmed)
                } else {
                    onError(responseTrimmed)
                }
            }
        }
        open(
            method = form.method,
            url = form.action
        )
        send(FormData().apply {
            append(
                name = file.name,
                value = file,
                fileName = file.name,
            )
        })
    }
}