import csstype.*
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img

val FileNotFound = FC<Props> { props ->
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            marginLeft = Auto.auto
            marginRight = Auto.auto
            fontFamily = FontFamily.monospace
            fontSize = 2.em
            gap = 1.em
        }
        +"File not found"
        img {
            src = "/404.webp"
        }
    }
}